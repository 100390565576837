//TOPの共通アニメーション

$(function(){
  //KVアニメーションクラス追加
  $(".c-topKV__smallRect").addClass("is-left_animation");
  $(".c-topKV__parallelRight-bottom").addClass("is-right_animation");
  $(".c-topKV__parallelRight").addClass("is-right_animation_changePoint");
  $(".c-topKV__rectLeft").addClass("is-left_animation_changePoint");


  $('.js-vision-swiper').on('inview', function(event, isInView) {
    if (isInView) {
      const visionSlider = new Swiper('.js-vision-swiper', {
        loop: false,
        effect: 'fade',
        speed: 450,
        autoplay: {
          delay: 0,
          disableOnInteraction: false,
          stopOnLastSlide: 'true',
        },
      });
    }
  });

});